<template>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium leading-5 text-gray-500">
      <slot name="name">{{ name }}</slot>
    </dt>
    <dd class="mt-1 text-base leading-5 text-gray-900">
      <slot>{{ value }}</slot>
    </dd>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      required: false,
      default: '',
    },
  },
};
</script>

<template>
  <div class="flex">
    <StarIcon
      class="w-14 h-14 text-lime-600"
      v-for="(item, index) in new Array(Math.round(rating)).fill('_')"
      :key="index"
    />
    <StarIcon
      class="w-14 h-14 text-gray-400"
      v-for="(item, index) in new Array(5 - Math.round(rating)).fill('_')"
      :key="index"
    />
  </div>
</template>

<script>
import StarIcon from '../../components/icons/StarIcon';
export default {
  components: {
    StarIcon,
  },
  props: {
    rating: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style></style>

<template>
  <a
    :href="`https://wa.me/2348075267678?text=${message}`"
    target="_blank"
    rel="noreferrer noopener"
    class="px-5 py-2 text-lg font-medium text-center text-white transition duration-150 rounded bg-cool-blue-500 hover:bg-cool-blue-400 active:bg-cool-blue-600"
  >
    <span class="mr-2">Make enquiry</span>
    <WhatsAppIcon class="inline-flex w-5 h-auto text-white" />
  </a>
</template>

<script>
import WhatsAppIcon from '@/components/icons/WhatsAppIcon';

export default {
  components: { WhatsAppIcon },
  props: {
    url: {
      type: String,
      default: '',
    },
  },

  computed: {
    message() {
      let text = "Hi, I'm interested in ";

      text += this.url;

      return encodeURIComponent(text);
    },
  },
};
</script>

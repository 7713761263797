<template>
  <div class="flex items-center sm:col-span-1">
    <CheckIconSlim class="w-auto h-6 text-green-500" />
    <span class="w-1"></span>
    <slot></slot>
  </div>
</template>

<script>
import CheckIconSlim from '@/components/icons/CheckIconSlim';

export default {
  components: { CheckIconSlim },
};
</script>

<template>
  <div>
    <PageSpinner v-if="listing.id < 1" />

    <div v-else>
      <div
        v-show="!showImages"
        class="w-full px-4 mx-auto mt-8 sm:mt-16 max-w-7xl"
      >
        <ImageGroup
          :images="listing.images"
          :coverImage="listing.cover_image"
          :id="listing.id"
          :slug="listing.slug"
        />

        <div
          class="top-0 flex flex-wrap w-full pb-10 bg-white border-b border-gray-200 lg:sticky lg:mx-auto z-20"
        >
          <div class="w-full pt-10 lg:w-2/3">
            <div class="lg:pr-16">
              <h2
                class="text-2xl leading-tight text-center font-heading-sans sm:text-3xl lg:text-left lg:leading-normal"
              >
                {{ listing.location.address_line_1 }}
              </h2>
              <div class="mt-2 text-center text-gray-600 lg:text-left">
                {{ listing.investment_type.name }}
                <span v-if="!isLand"
                  >&#8226; &nbsp; {{ listing.beds }} bed
                  {{ listing.baths }} bath</span
                >
                <span v-if="listing.lot_size"
                  >&nbsp; &#8226; &nbsp; {{ listing.lot_size }} sqm</span
                >
                <span v-if="listing.parking_spaces"
                  >&nbsp; &#8226; &nbsp; {{ listing.parking_spaces }} parking
                  spaces</span
                >
                <span class="capitalize" v-if="listing.land_texture"
                  >&nbsp; &#8226; &nbsp; {{ listing.land_texture }}</span
                >
                <span v-if="listing.year_built"
                  >&nbsp; &#8226; &nbsp; Built {{ listing.year_built }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="w-full mt-5 lg:mt-10 lg:w-1/3 lg:border-l lg:border-gray-200"
          >
            <div class="lg:pl-16">
              <div>
                <div class="text-2xl font-medium text-center">
                  &#8358;{{ Number(listing.price).toLocaleString() }}
                </div>
                <div class="mt-2 text-center">
                  <EnquiryButton :url="listingUrl" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Details section -->
        <div class="mt-10">
          <div
            class="grid grid-cols-1 gap-y-8 md:grid-cols-4 md:gap-x-6 lg:gap-x-10"
          >
            <div class="col-span-1 md:col-span-4">
              <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Description
                </h3>
              </div>

              <div v-show="listing.description" class="px-4 py-5 sm:px-6">
                {{ listing.description }}
              </div>

              <div
                v-if="!isLand && theresPropertyFeatures"
                class="mt-6 overflow-hidden sm:mt-10"
              >
                <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Property Features
                  </h3>
                </div>
                <div class="px-4 py-5 sm:px-6">
                  <div class="grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-2">
                    <FeatureListItem v-if="listing.air_conditioners">
                      <span
                        >Air Conditioners({{ listing.air_conditioners }})</span
                      >
                    </FeatureListItem>

                    <FeatureListItem v-if="listing.wine_cellars">
                      <span>Wine Cellars({{ listing.wine_cellars }})</span>
                    </FeatureListItem>

                    <FeatureListItem v-if="listing.cctv">
                      <span>CCTV</span>
                    </FeatureListItem>

                    <FeatureListItem v-if="listing.serviced">
                      <span>Serviced</span>
                    </FeatureListItem>
                  </div>
                </div>
              </div>

              <div v-if="listing.latest_neighbourhood_ratings.length !== 0">
                <div
                  class="flex items-center px-4 py-5 mt-6 border-b border-gray-200 sm:px-6"
                >
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Neighbourhood Overview
                  </h3>
                  <span
                    class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-cool-blue-100 text-cool-blue-800 ml-5"
                  >
                    Beta
                  </span>
                </div>
                <div class="px-4 py-5 sm:px-6">
                  <div
                    class="flex flex-col space-x-0 md:space-x-16 md:flex-row"
                  >
                    <div class="w-full sm:w-2/3 md:w-1/4">
                      <img
                        src="@/assets/img/school-rating.jpg"
                        class="object-cover w-full h-32 rounded"
                      />
                      <h3 class="mt-3 font-semibold text-gray-700 text-md">
                        Schools
                      </h3>
                      <ul>
                        <li class="text-sm text-gray-500">
                          {{ listing.latest_neighbourhood_ratings[0].schools }}
                          {{
                            listing.latest_neighbourhood_ratings[0].schools > 1
                              ? 'Schools'
                              : 'School'
                          }}
                        </li>
                      </ul>
                    </div>

                    <div class="w-full mt-10 sm:w-2/3 md:w-1/4 md:mt-0">
                      <img
                        src="@/assets/img/hospital-rating.jpg"
                        class="object-cover w-full h-32 rounded"
                      />
                      <h3 class="mt-3 font-semibold text-gray-700 text-md">
                        Health Care
                      </h3>
                      <ul>
                        <li class="text-sm text-gray-500">
                          {{
                            listing.latest_neighbourhood_ratings[0].hospitals
                          }}
                          {{
                            listing.latest_neighbourhood_ratings[0].hospitals >
                            1
                              ? 'Hospitals'
                              : 'Hospital'
                          }}
                        </li>
                        <li class="text-sm text-gray-500">
                          {{
                            listing.latest_neighbourhood_ratings[0].pharmacies
                          }}
                          {{
                            listing.latest_neighbourhood_ratings[0].pharmacies >
                            1
                              ? 'Pharmacies'
                              : 'Pharmacy'
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="w-full mt-10 sm:w-2/3 md:w-1/4 md:mt-0">
                      <img
                        src="@/assets/img/bank-rating.jpg"
                        class="object-cover w-full h-32 rounded"
                      />
                      <h3 class="mt-3 font-semibold text-gray-700 text-md">
                        Banks
                      </h3>
                      <ul>
                        <li class="text-sm text-gray-500">
                          {{ listing.latest_neighbourhood_ratings[0].banks }}
                          {{
                            listing.latest_neighbourhood_ratings[0].banks > 1
                              ? 'Banks'
                              : 'Bank'
                          }}
                        </li>
                        <li class="text-sm text-gray-500">
                          {{ listing.latest_neighbourhood_ratings[0].atms }}
                          {{
                            listing.latest_neighbourhood_ratings[0].atms > 1
                              ? 'ATMs'
                              : 'ATM'
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="w-full mt-10 sm:w-2/3 md:w-1/4 md:mt-0">
                      <img
                        src="@/assets/img/fun-rating.jpg"
                        class="object-cover w-full h-32 rounded"
                      />
                      <h3 class="mt-3 font-semibold text-gray-700 text-md">
                        Fun/Groceries
                      </h3>
                      <ul>
                        <li class="text-sm text-gray-500">
                          {{ listing.latest_neighbourhood_ratings[0].hotels }}
                          {{
                            listing.latest_neighbourhood_ratings[0].hotels > 1
                              ? 'Hotels'
                              : 'Hotel'
                          }}
                        </li>
                        <li class="text-sm text-gray-500">
                          {{
                            listing.latest_neighbourhood_ratings[0].restaurants
                          }}
                          {{
                            listing.latest_neighbourhood_ratings[0]
                              .restaurants > 1
                              ? 'Restaurants'
                              : 'Restaurant'
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div v-if="listing.latest_neighbourhood_ratings.length !== 0">
                  <div
                    class="flex items-center px-4 py-5 mt-6 border-b border-gray-200 sm:px-6"
                  >
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Neighbourhood Rating
                    </h3>
                    <span
                      class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-cool-blue-100 text-cool-blue-800 ml-5"
                    >
                      Beta
                    </span>
                  </div>
                  <div class="px-4 py-5 sm:px-6">
                    <Stars
                      :rating="
                        listing.latest_neighbourhood_ratings[0].score / 20
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="mt-6 overflow-hidden sm:mt-10">
                <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Documents
                  </h3>
                </div>
                <div class="px-4 py-5 sm:px-6">
                  <div class="grid grid-cols-1 gap-y-4 gap-x-4">
                    <FeatureListItem v-if="listing.c_of_o_number">
                      <span>C of O</span>
                    </FeatureListItem>

                    <FeatureListItem v-if="listing.governors_consent_number">
                      <span>Governor's Consent</span>
                    </FeatureListItem>

                    <FeatureListItem v-if="listing.deed_of_assignment">
                      <span>Deed of Assignment</span>
                    </FeatureListItem>

                    <FeatureListItem v-if="listing.excision_number">
                      <span>Excision</span>
                    </FeatureListItem>

                    <FeatureListItem v-if="listing.receipt">
                      <span>Receipt</span>
                    </FeatureListItem>
                  </div>
                </div>
              </div>

              <div
                v-if="theresFinancialInformation"
                class="mt-6 overflow-hidden sm:mt-10"
              >
                <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Financial Information
                  </h3>
                </div>
                <div class="px-4 py-5 sm:px-6">
                  <dl class="grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-2">
                    <DescriptionListItem
                      v-if="listing.cap_rate"
                      name="Cap rate"
                      :value="listing.cap_rate + '%'"
                    />
                    <DescriptionListItem
                      v-if="listing.internal_rate_of_return"
                      name="Internal Rate of Return"
                      :value="listing.internal_rate_of_return + '%'"
                    />
                    <DescriptionListItem
                      v-if="listing.net_operating_income"
                      name="Net Operating Income"
                    >
                      &#8358;{{
                        Number(listing.net_operating_income).toLocaleString()
                      }}
                    </DescriptionListItem>

                    <DescriptionListItem
                      v-if="listing.rent"
                      name="Expected Rent"
                    >
                      &#8358;{{ Number(listing.rent).toLocaleString() }}
                    </DescriptionListItem>

                    <DescriptionListItem
                      v-if="listing.land_use_charge"
                      name="Estimated Land Use Charge"
                    >
                      &#8358;{{
                        Number(listing.land_use_charge).toLocaleString()
                      }}
                    </DescriptionListItem>
                  </dl>
                </div>
              </div>

              <div class="px-4 py-5 border-b border-gray-200 sm:px-6 mt-7">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Buy with Float Estimator
                </h3>
              </div>

              <MortgageCalculator
                :isEmbedded="true"
                :initialTotalCost="String(listing.price)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- All the images -->
      <div v-if="showImages" class="w-full px-4 mx-auto">
        <div class="w-full">
          <div class="pt-6">
            <div class="text-lg text-gray-700 md:text-2xl">
              <button
                @click="
                  $router.push(
                    `/properties/for-sale/${listing.id}/${listing.slug}`
                  )
                "
                class="px-3 py-2 mr-3 transition duration-150 rounded-full hover:bg-gray-200 md:px-4 md:py-2"
              >
                &larr;
                <span class="inline-block">Back</span>
              </button>
            </div>
          </div>
        </div>

        <div class="pt-6">
          <div class="grid grid-cols-1 gap-y-4">
            <img
              v-for="image in listing.images"
              :key="image.id"
              class="w-full h-auto shadow-inner"
              :src="fullImageSrc(image.path)"
              loading="lazy"
            />
          </div>
        </div>

        <div class="w-full my-6">
          <div class="pt-6">
            <div class="text-lg text-gray-700 md:text-2xl">
              <button
                @click="
                  $router.push(
                    `/properties/for-sale/${listing.id}/${listing.slug}`
                  )
                "
                class="px-3 py-2 mr-3 transition duration-150 rounded-full hover:bg-gray-200 md:px-4 md:py-2"
              >
                &larr;
                <span class="inline-block">Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Contact v-show="!showImages" class="mt-16" />
  </div>
</template>

<script>
import PageSpinner from '@/components/PageSpinner';
import Contact from '@/components/common/Contact';
import DescriptionListItem from './DescriptionListItem';
import MortgageCalculator from '../MortgageCalculator/MortgageCalculator.vue';
import FeatureListItem from './FeatureListItem';
import EnquiryButton from './EnquiryButton';
import ImageGroup from './ImageGroup';
import Stars from './Stars';

const cdnPrefix = process.env.VUE_APP_CDN_PREFIX;

export default {
  components: {
    PageSpinner,
    Contact,
    EnquiryButton,
    DescriptionListItem,
    FeatureListItem,
    ImageGroup,
    Stars,
    MortgageCalculator,
  },

  head() {
    const { isLand } = this;
    const {
      id,
      investment_type,
      beds,
      lot_size,
      location,
      cover_image,
      description,
    } = this.listing;

    let title;
    if (id) {
      const titlePrefix = isLand ? `${lot_size} sqm` : `${beds} Bedroom`;
      title = `${titlePrefix} ${investment_type.name}, ${location.locality}`;
    }

    const previewImageSrc = `${cdnPrefix}/tr:q-85,w-800/${cover_image.path}`;

    let metaDescription = 'Make an enquiry today!';
    if (typeof description === 'string') {
      /** @see https://stackoverflow.com/a/12317342/3083410 */
      metaDescription =
        description.replace(/^[.\s]+|[.\s]+$/g, '') + `. ${metaDescription}`;
    }

    return {
      title,
      meta: [
        { vmid: 'og:title', property: 'og:title', content: title },
        { vmid: 'og:image', property: 'og:image', content: previewImageSrc },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: metaDescription,
        },
        { vmid: 'description', name: 'description', content: metaDescription },
      ],
    };
  },

  computed: {
    listing() {
      return this.$store.state.listing;
    },

    isLand() {
      return this.listing.investment_type.slug === 'land';
    },

    coverImageSrc() {
      return `${cdnPrefix}/tr:q-70,w-1800/${this.listing.cover_image.path}`;
    },

    purposes() {
      const purposes = [];

      if (this.listing.for_residential_use) {
        purposes.push('Residential');
      }

      if (this.listing.for_commercial_use) {
        purposes.push('Commercial');
      }

      return purposes.join(', ');
    },

    theresFinancialInformation() {
      return (
        this.listing.cap_rate ||
        this.listing.internal_rate_of_return ||
        this.listing.net_operating_income ||
        this.listing.rent
      );
    },

    theresPropertyFeatures() {
      return (
        this.listing.wine_cellars ||
        this.listing.air_conditioners ||
        this.listing.cctv ||
        this.listing.serviced
      );
    },

    showImages() {
      return (
        this.$route && this.$route.path && this.$route.path.includes('images')
      );
    },

    listingUrl() {
      return typeof window !== 'undefined' ? window.location.href : null;
    },
  },

  serverPrefetch() {
    return this.getListing(this.$route.params.id);
  },

  mounted() {
    if (!this.listing.id) {
      this.getListing(this.$route.params.id).then(() => {
        if (typeof this.$route.path === 'string') {
          if (this.$route.path.startsWith('/listings')) {
            this.$router.push(
              `/properties/for-sale/${this.listing.id}/${this.listing.slug}`
            );
          }
        }
      });
    }
  },

  methods: {
    getListing(id) {
      return this.$store
        .dispatch('getListing', id)
        .catch(() => this.$router.push('/404'));
    },

    fullImageSrc(path) {
      return `${cdnPrefix}/${path}`;
    },
  },
};
</script>

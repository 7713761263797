<template>
  <div class="relative w-full overflow-hidden rounded-md">
    <!-- Image count -->
    <div
      class="absolute z-10 flex items-center justify-around text-white bg-gray-600 rounded-md w-14 h-7 bottom-2 right-3"
    >
      <PhotoIcon class="w-6 text-gray-300" />
      {{ allImages.length }}
    </div>
    <!-- Desktop Images -->
    <div
      class="hidden w-full space-x-2 overflow-hidden transition-transform duration-500 transform cursor-pointer hover:scale-105 lg:flex sm:h-1/2screen lg:h-2/3screen"
      @click="showModal = true"
    >
      <div class="relative overflow-hidden lg:w-2/3">
        <v-lazy-image
          :src="coverImageSrc"
          class="object-cover w-full h-full rounded-l-lg"
        />
      </div>
      <div class="hidden w-1/3 h-full gap-2 lg:inline-grid">
        <div class="overflow-hidden">
          <v-lazy-image
            :src="fullImageSrc(images[0].path)"
            class="object-cover w-full h-full rounded-tr-lg"
          />
        </div>
        <div class="overflow-hidden">
          <v-lazy-image
            :src="fullImageSrc(images[1].path)"
            class="object-cover w-full h-full rounded-br-lg"
          />
        </div>
      </div>
    </div>
    <!-- Mobile Image -->
    <router-link class="block w-full rounded-lg" :to="`/properties/for-sale/${id}/${slug}/images`">
      <div
        class="relative flex w-full space-x-2 overflow-hidden rounded-lg cursor-pointer h-1/3screen md:h-1/2screen lg:h-2/3screen lg:hidden"
      >
        <img
          :src="coverImageSrc"
          class="object-cover object-bottom w-full h-full rounded-lg"
        />
      </div>
    </router-link>
    <!-- Modal portal -->
    <portal to="image-modal">
      <div
        v-if="showModal"
        ref="modal"
        class="absolute z-20 flex flex-col items-center w-full h-screen outline-none"
        style="background: rgba(0, 0, 0, 0.8);"
        tabindex="0"
        @click.self="showModal = false"
        @keyup.esc="showModal = false"
        @keyup.left="prev"
        @keyup.right="next"
      >
        <button
          class="absolute z-30 transition-transform duration-300 transform top-10 right-15"
          @click="showModal = false"
        >
          <XIcon
            class="w-10 h-10 text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
          />
        </button>
        <button
          class="absolute z-30 transition-transform duration-300 transform top-1/2 left-10 hover:scale-110"
          @click="prev"
        >
          <LeftArrowIcon
            class="w-12 h-12 text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
          />
        </button>
        <button
          class="absolute z-30 transition-transform duration-300 transform top-1/2 right-10 hover:scale-110"
          @click="next"
        >
          <RightArrowIcon
            class="w-12 h-12 text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
          />
        </button>
        <div class="z-10 flex items-center justify-center text-white h-1/12">
          {{ (currentIndex % allImages.length) + 1 }} / {{ allImages.length }}
        </div>
        <v-lazy-image
          :src="fullImageSrc(currentImage)"
          class="object-cover rounded-md h-10/12"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import VLazyImage from 'v-lazy-image';
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue';
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue';
import XIcon from '@/components/icons/XIcon.vue';
import PhotoIcon from '@/components/icons/PhotoIcon.vue';

const cdnPrefix = process.env.VUE_APP_CDN_PREFIX;

export default {
  components: {
    VLazyImage,
    LeftArrowIcon,
    RightArrowIcon,
    XIcon,
    PhotoIcon,
  },
  props: {
    images: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: Number,
    },
    slug: {
      required: true,
      type: String,
    },
    coverImage: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      currentIndex: 0,
      showModal: false,
      allImages: [{ path: this.coverImage.path }, ...this.images],
    };
  },

  computed: {
    coverImageSrc() {
      return `${cdnPrefix}/tr:q-70,w-1800/${this.coverImage.path}`;
    },

    currentImage() {
      return this.allImages[this.currentIndex % this.allImages.length].path;
    },
  },

  watch: {
    showModal: function () {
      if (typeof window !== 'undefined') {
        if (this.showModal) {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';

          //For some reason modal wasn't immediately present on the refs list. Ref is needed to make the escape button work
          //This adds a  delay of 500ms before focusing the div
          setTimeout(() => {
            this.$refs.modal.focus();
          }, 500);
          return;
        }

        document.body.style.overflow = 'auto';
      }
    },
  },

  // created() {
  //   if (typeof window !== 'undefined') {
  //     //Preloads images to cache so scrolling is smooth
  //     [
  //       { path: `tr:q-70,w-1800/${this.coverImage.path}` },
  //       ...this.images,
  //     ].forEach((img) => {
  //       const image = new Image();
  //       image.src = this.fullImageSrc(img.path);
  //     });
  //   }
  // },

  methods: {
    fullImageSrc(path) {
      return `${cdnPrefix}/${path}`;
    },
    next() {
      this.currentIndex += 1;
    },
    prev() {
      this.currentIndex === 0
        ? (this.currentIndex = this.images.length)
        : (this.currentIndex -= 1);
    },
    handleClick() {
      this.showmodal = true;
      this.$router.push(`/listings/${this.id}/images`);
    },
  },
};
</script>
